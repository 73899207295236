import React from "react"
import { Link } from "gatsby"

//Import Components
import Navbar from '../components/navbar'
import Contact from '../components/contact'
import Footer from '../components/footer'
import ImgCheckMark from "../images/check-mark.png"

const Thanks = () => (
    <div>
        <a href="#" className="scrolltop" id="scroll-top">
            <i className="bx bx-chevron-up scrolltop__icon"></i>
        </a>
        <Navbar />
        <main className="l-main">
            <div className="thanks__container bd-container">
                <div className="thanks__sub-container" data-sal="zoom-in" data-sal-delay="100" data-sal-duration="800" data-sal-easing="ease">
                    <img src={ImgCheckMark} className="thanks__img" />
                    <h1 className="thanks__title">Thank You!</h1>
                    <p className="thanks__description">We've received your message and we'll get back to you within 24 hours.</p>
                    <Link to="/" className="thanks__button">Got It</Link>
                </div>

            </div>
            <Contact />
            <Footer />
        </main>
    </div>
)

export default Thanks